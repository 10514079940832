const CONFIG = {
  phoneOperatorFormat: '0(10|50|51|55|60|70|77|99)',
  apiBaseUrl: {
    production: 'https://api.digit-u.id',
    staging: 'https://staging.digit-u.id/api'
  },
  returnToDefault: {
    url: 'https://turbo.az',
    encodedUrl: 'aHR0cHM6Ly90dXJiby5hei8='
  },
  queryPathNames: {
    auth: '/auth',
    verify: '/auth/verify'
  },
  allowedHosts: ['turbo.az', 'turboaz', 'bina.az', 'binaaz', 'tap.az', 'tapaz']
};

export default CONFIG;
